export const QuizData = [
  {
    question: "लेक लाडकी योजना केव्हापासून लागू आहे ?",
    options: [
      "1 जानेवारी 2024",
      "1 डिसेंबर 2023",
      "1 एप्रिल 2023",
      "1 एप्रिल 2022",
    ],
    answer: 3,
  },
  {
    question:
      "लेक लाडकी योजनेचा लाभ कुटुंबातील जन्माला येणाऱ्या किती मुलींना लागू राहील ?",
    options: ["4", "3", "2", "1"],
    answer: 3,
  },
  {
    question:
      "लेक लाडकी या योजनेचा लाभ घेण्यासाठी कोणते रेशन कार्ड आवश्यक आहे?",
    options: [
      "पांढरे शिधापत्रिका",
      "पिवळे अथवा केशरी शिधापत्रिका",
      "केशरी शिधापत्रिका",
      "पिवळे शिधापत्रिका",
    ],
    answer: 2,
  },
  {
    question: "लेक लाडकी योजनेचा अर्ज कोणाकडे सादर करावयाचा आहे?",
    options: ["ग्रामसेवक", "अंगणवाडी सेविका", "सरपंच", "आशा सेविका"],
    answer: 2,
  },
  {
    question:
      "दुसऱ्या प्रसूतीच्या वेळी जुळी अपत्ये जन्माला आल्यास एक मुलगी किंवा दोन्ही मुलींना या योजनेचा लाभ देता येईल किंवा कसे?",
    options: ["होय", "नाही"],
    answer: 1,
  },

  {
    question:
      "कुटुंब नियोजन शस्त्रक्रिया प्रमाणपत्र केव्हा सादर करणे आवश्यक आहे ?",
    options: [
      "पहिल्या आपत्याच्या तिसऱ्या हप्त्यासाठी व दुसऱ्या अपत्याच्या दुसऱ्या हप्त्यासाठी",
      "एक वर्षाचे आत",
      "मुलीचा जन्म झाल्यानंतर लगेच अर्ज सादर करतेवेळी",
    ],
    answer: 1,
  },
  {
    question: "नागरी भागामध्ये ही योजना लागू आहे काय ?",
    options: ["होय ", "नाही"],
    answer: 1,
  },
  {
    question:
      "एक एप्रिल 2023 पूर्वी एक मुलगी किंवा मुलगा आहे व त्यानंतर जन्माला आलेल्या दुसऱ्या मुलीस  किंवा जुळ्या मुलींना ही योजना अनुदेय राहील किंवा कसे?",
    options: ["होय ", "नाही"],
    answer: 1,
  },
  {
    question: "लाभार्थी कुटुंब महाराष्ट्रातील रहिवासी असणे आवश्यक आहे काय ?",
    options: ["नाही", "होय"],
    answer: 2,
  },
  {
    question:
      "लाभार्थी कुटुंबाचे वार्षिक उत्पन्न मर्यादा किती आहे ? कोणत्या अधिकाऱ्याचा दाखला आवश्यक आहे?",
    options: [
      "तलाठ्याचा एक लक्ष रुपये किमतीचा उत्पन्नाचा दाखला",
      "सरपंचाचा एक लक्ष रुपये किमतीच्या उत्पन्नाचा दाखला",
      "ग्रामसेवकाचा एक लाख रुपये किमतीच्या उत्पन्नाचा दाखला",
      "तहसीलदारांचा एक लक्ष रुपये किमतीच्या उत्पन्नाचा दाखला",
    ],
    answer: 4,
  },
  {
    question: "या योजनेच्या पहिल्या लाभासाठी कोणती कागदपत्रे आवश्यक आहे ?",
    options: [
      "लाभार्थ्यांचा जन्माचा दाखला",
      "कुटुंबप्रमुखांचा उत्पन्नाचा दाखला",
      "पालकांचे आधार कार्ड",
      "बँकेच्या पासबुकची पहिल्या पानाची छायांकित प्रत",
      "रेशन कार्ड पिवळे अथवा केशरी",
      "वरीलपैकी सर्व",
    ],
    answer: 6,
  },
  {
    question: "या योजनेची लाभ घेण्याची कार्यपद्धती काय आहे?",
    options: [
      "अंगणवाडी सेविकेकडून अर्ज भरून घ्यावा",
      "व्यक्तीने प्रमाणपत्राची छाननी करून बालविकास प्रकल्प अधिकारी यांच्याकडे सादर करावा",
      "बाल विकास प्रकल्प अधिकारी यांनी जिल्हा कार्यक्रम अधिकारी यांच्याकडे अर्जासह यादी सादर करावी",
      "जिल्हा कार्यक्रम अधिकारी यांनी छाननी करून यादीस मान्यता देऊन आयुक्ताला सादर करावे",
      "वरीलपैकी सर्व पर्याय लागू आहे",
    ],
    answer: 5,
  },
  {
    question: "लेक लाडकी या योजनेचे उद्दिष्ट काय आहेत?",
    options: [
      "मुलीच्या जन्मास प्रोत्साहन देऊन मुलीचा जन्मदर वाढवणे",
      "मुलीच्या शिक्षणास चालना देणे",
      "मुलीचा मृत्यू दर कमी करणे व बालविवाह रोखणे",
      "कुपोषण कमी करणे",
      "शाळाबाह्य मुलींचे प्रमाण शून्यावर आणण्यासाठी प्रोत्साहित करणे",
      "वरीलपैकी सर्व पर्याय लागू आहेत",
    ],
    answer: 6,
  },
  {
    question: "या योजनेचा लाभ कसा मिळणार आहे ?",
    options: [
      "मुलगी पहिलीत गेल्यावर 6000 रुपये.",
      "मुलगी अकरावीत गेल्यावर 8000रुपये",
      "मुलगी मुलीला 18 वर्ष पूर्ण झाल्यावर 75,000 रुपये",
      "मुलीचा जन्म झाल्यावर 5000 रुपये",
      "वरीलपैकी सर्व पर्याय योग्य आहे",
    ],
    answer: 5,
  },
  {
    question:
      "लेक लाडकी योजनेच्या अंतिम लाभाकरिता मुलीने अविवाहीत असणे  आवश्यक आहे का? ",
    options: ["होय", "नाही"],
    answer: 1,
  },
  {
    question:
      "माझी कन्या भाग्यश्री  योजना ज्यांना लागु आहे त्यांचा  अर्ज स्वीकारण्याची  अंतिम दिनांक कोणती? ",
    options: [
      "01 एप्रिल 2023",
      "30 सप्टेंबर 2023",
      "31 डिसेंबर 2023",
      "31 मार्च 2024",
    ],
    answer: 3,
  },
  {
    question: "लेक लाडकी योजनेच्या पहिल्या लाभावेळी कोणती अट शिथील राहील?",
    options: [
      " रेशन कार्ड",
      "पालकाचे उत्पन्न दाखला",
      "बँक खाते तपशील",
      "लाभार्थ्यांचे आधार कार्ड",
    ],
    answer: 4,
  },
  {
    question: "लेक लाडकी या  योजनेचा  लाभ  कसा मिळणार आहे?",
    options: [
      " मुलगी पहिलीत गेल्यावर 6000 रुपये",
      "मुलगी  अकरावीत गेल्यावर 8000 रुपये",
      "मुलगी सहावीत गेल्यावर 7000 रुपये",
      "18 वर्ष पुर्ण झाल्यावर 75000 रुपये",
      "मुलीचा जन्म झाल्यावर 5000 रुपये",
      "वरील सर्व पर्याय बरोबर",
    ],
    answer: 6,
  },
  {
    question:
      "लेक लाडकी या  योजनेचा  अंतिम लाभ मुलीला वयाच्या कोणत्या वर्षी  मिळणार आहे?",
    options: [
      "मुलगी 6 वर्षाची झाल्यावर",
      "मुलगी 12 वर्षाची झाल्यावर",
      "मुलगी 18 वर्षाची झाल्यावर",
      "मुलगी 21 वर्षाची झाल्यावर",
    ],
    answer: 3,
  },
  {
    question:
      "लेक लाडकी या योजनेसाठी पात्र होण्यासाठी पालकाचे उत्पन्न मर्यादा किती आहे?",
    options: ["1 लक्ष रुपये", "5 लक्ष रुपये", "7.5 लक्ष", " 8 लक्ष रुपये"],
    answer: 1,
  },
];