import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="landing-page">
      <header className="header">
        <img src="/logo.png" alt="Logo" className="logo" />
        <h3>
          महिला व बाल विकास विभाग <br></br> जिल्हा परिषद सिंधुदुर्ग <br></br>{" "}
          प्रस्तुती
        </h3>
      </header>
      <div className="content">
        <p className="heading-txt1">लेक लाडकी</p>
        <p>
          योजना प्रचार प्रसिद्धी साठी क्षेत्रिय अधिकारी कर्मचारी व नागरिक
          यांच्यासाठी
        </p>
        <p>
          सदर प्रश्नावर <span className="heading-txt2">प्रश्नमंजुषा</span>{" "}
          <br></br><span className="heading-txt3">महिला व बाल विकास विभाग, जिल्हा परिषद सिंधुदुर्ग</span>
          यांची निर्मिती असून सर्वांसाठी मोफत स्वरुपात उपलब्ध आहे.
        </p>
      </div>
      {/* Navigation Menu */}
      <nav className="navigation-menu p-2 border">
        <ul>
          <li>
            <Link to="/">मुख्यपृष्ठ</Link>
          </li>
          <li>
            <Link to="/quiz">प्रश्नमंजुषा सुरू करा</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
