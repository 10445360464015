import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Modified import statement
import Home from "./components/Home";
import Quiz from './components/Quiz';
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {" "}
          {/* Changed to Routes */}
          <Route path="/" element={<Home />} /> {/* Updated syntax */}
          <Route path="/quiz" element={<Quiz />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
