import React from "react";

function QuizResult(props) {
  const resultStyle = {
    textAlign: "center",
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "white",
    color: "black",
    borderRadius: "5px",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    maxWidth: "300px",
    margin: "auto",
  };

  const buttonStyle = {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const ptyle = {
    color:"black",
    fontweight:"bold",

  };
   const ptyle1 = {
     color: "blue",
     fontweight: "bold",
   };

  return (
    <>
      <div style={resultStyle}>
        <p style={ptyle}>
          'लेक लाडकी' 'या योजनेच्या जास्तीत जास्त प्रचार व प्रसार यासाठी सदर
          प्रश्नमंजुषा तयार करण्यात आलेली आहे.
        </p>
        <button style={buttonStyle} onClick={props.tryAgain}>
          Try Again
        </button>
      </div>
      <div className="show-score">
        <p>Your Score: {props.score}</p>
        <p>Total Score: {props.totalScore}</p>
      </div>
      <p style={ptyle1}>
        या योजनेच्या अधिक माहितीसाठी जवळची अंगणवाडी, बाल विकास प्रकल्प अधिकारी
        कार्यालय यांच्याशी संपर्क साधता येईल.
      </p>
    </>
  );
}

export default QuizResult;
