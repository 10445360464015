import React, { useState } from "react";
import { QuizData } from "../Data/QuizData";
import QuizResult from "./QuizResult";

function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [clickedOption, setClickedOption] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const changeQuestion = () => {
    updateScore();
    if (currentQuestion < QuizData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setClickedOption(0);
    } else {
      setShowResult(true);
    }
  };

  const updateScore = () => {
    if (clickedOption === QuizData[currentQuestion].answer) {
      setScore(score + 1);
    }
  };

  const resetAll = () => {
    setShowResult(false);
    setCurrentQuestion(0);
    setClickedOption(0);
    setScore(0);
  };

  const checkAnswer = (optionIndex) => {
    const correctAnswer = QuizData[currentQuestion].answer;
    if (clickedOption !== 0) {
      if (clickedOption === optionIndex + 1) {
        if (clickedOption === correctAnswer) {
          return { backgroundColor: "green", color: "white" }; // Correct answer
        } else {
          return { backgroundColor: "red", color: "white" }; // Incorrect answer
        }
      } else if (optionIndex + 1 === correctAnswer) {
        return { backgroundColor: "green", color: "white" }; // Highlight correct answer
      }
    }
    return { backgroundColor: "white", color: "black" }; // Default style for other options
  };

  return (
    <div
      style={{
        background: "#FFC0CB",
        color: "black",
        padding: "20px",
        borderRadius: "10px",
        maxWidth: "1400px",
        margin: "auto",
        textAlign:"center",
      }}
    >
      <img src="/logo.png" alt="Logo" style={{ width: "100px", height: "100px"  }} />{" "}
      {/* Display the logo */}

      <p className="heading-txt1">प्रश्नमंजुषा</p>
      <div className="container">
        {showResult ? (
          <QuizResult
            score={score}
            totalScore={QuizData.length}
            tryAgain={resetAll}
          />
        ) : (
          <>
            <div className="question">
              <span id="question-number">{currentQuestion + 1}. </span>
              <span id="question-txt">
                {QuizData[currentQuestion].question}
              </span>
            </div>
            <div className="option-container">
              {QuizData[currentQuestion].options.map((option, i) => {
                return (
                  <button
                    style={checkAnswer(i)}
                    key={i}
                    onClick={() => setClickedOption(i + 1)}
                    disabled={clickedOption !== 0}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
            <input
              type="button"
              value="Next"
              id="next-button"
              onClick={changeQuestion}
              disabled={clickedOption === 0}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Quiz;
